@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;1,300;1,400;1,700&family=Poppins:ital,wght@0,100;0,200;0,700;1,100;1,500;1,700&display=swap');


.App {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
  font-style: normal;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}



/* Hero CSS */

@font-face {
  font-family: 'MigraBold';
  src: url('./data/Migra-Extrabold.ttf') format('truetype');

  /* font-family: 'MigraLight';
  src: url('./data/Migra-Extralight.ttf') format('truetype'); */
}
.hero-logo-text{
  color: #000;
  font-family: 'MigraBold';
  font-weight: normal;
  font-style: normal;
}

/* About CSS */

.about-section, .location-section{
  background-color: #1b1b1b;
  padding: 3rem;
}

.about-title{
  font-family: 'MigraBold';
  text-align: left;
  color: #fff;
  padding: 1rem;  
}

.about-text{
  color: #d1d1d1;
  text-align: left;
  padding: 1rem;
}

/* Menu CSS */
.menu-section{
  background-color: #1b1b1b;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.menu-title{
  margin-top: 6rem;
  font-family: 'MigraBold';
  color: #fff;
  padding: 1rem;
  text-transform: uppercase;
}
.sub-menu{
  padding-top: 1rem;
  margin-bottom: 1rem;
}
.menu-sub-title{
  color: #fff;
  font-size: 24px;
  margin-bottom: 2rem;
  font-weight: 500;
  font-family: 'MigraBold';
  padding-bottom: 1.5rem;
  border-bottom: 2px solid #f68713;
}
.card-item{
  border: 1px solid rgba(250, 250, 250, 0.2);
  background: #101010;
  border-radius: 0;
  margin: 20px;
  overflow: hidden;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
  transition: opacity 0.3s, transform 0.3s, background-color 0.3s;
  transition: 0.3s ease-in-out;
  position: relative;
}

.card-item:hover{
  border: 1px solid #f68713;
}

.card-item:hover h4, .card-item:hover p{
  color: #f68713;
}


/* Gallery CSS */

.gallery-section{
  background-color: #101010;
  padding: 3rem;
}

.gallery-title{
  font-family: 'MigraBold';
  color: #fff;
  padding: 3rem;
}




/* FOOTER CSS */

.footer {
  background-color: #101010;
  padding-top: 1rem 1rem 1rem 3rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.footer-content {
  text-align: left;
  padding-top: 3rem;
}

.footer-logo-image {
  padding: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
  /* border: 2px solid black; */
}

.social-icons {
  padding-top: 1.5rem;
  text-align: center;
}

.social-icons a {
  font-size: 16px;
  color: #f68713;
  margin-right: 0.8rem;
  padding: 2px;
}

.social-icons a:hover {
  color: #fff;
  font-weight: bold;
}

.footer-sub-heads {
  color: #fff;
  font-size: 18px;
  margin-bottom: 1rem;
  font-weight: 500;
  font-family: 'MigraBold';
}


.footer-links div {
  padding-top: 5px;
  padding-bottom: 5px;
}

.footer-links div:hover {
  padding-left: 6px;
  transition: 0.2s ease-in-out;
}

.footer-link-tag {
  text-decoration: none;
  color: #868c98;
  font-weight: 300;
}

.footer-link-tag:hover {
  color: #f68713;
}

.footer-margin {
  margin-bottom: 6rem;
}

.footer-line{
  color: #868c98;
  font-weight: 500;
  padding-bottom: 1rem;
}

@media screen and (max-width: 768px){

  .about-section{
    padding: 1rem;
  }
  .about-title{
    font-family: 'MigraBold';
    text-align: center;
    padding-top: 20px;
  }
  
  .about-text{
    color: #d1d1d1;
    text-align: left;
    padding: 10px;
  }

  .footer-sub-heads{
    margin-top: 4rem;
  }
  .footer-logo-image {
    padding: 5rem;
    /* height: 300px;
    width: 300px; */
    display: block;
    margin-left: auto;
    margin-right: auto;
    /* border: 2px solid black; */
  }

}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}