/* ============ NAVBAR ============ */

.nav-menu{
    display: grid;
    grid-template-columns: repeat(6, auto);
    grid-gap: 10px;
    list-style: none;
    align-items: center;
    text-align: center;
    justify-content: end;
}

.responsive-logo{
    display: none;
}

.nav-link-primary{
    color: #f68713 !important;
}

@media screen and (max-width: 768px){
    .nav-menu{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        background-color: rgba(255, 255, 255, .25);    
        box-shadow: 0 8px 32px 0 rgba(31, 38, 135, .37);
        backdrop-filter: blur(4px);
        border-radius: 25px;
        width: 100%;
        height: auto;
        position: absolute;
        top: 0;    
        left: -110%;
        align-items: stretch;
        padding: 80px 0 30px 0;
        margin: 0;
        z-index: -1;
        transition: all 0.3s ease-in-out;
    }       
    .nav-menu.active{
        left: 0%;
    }

    .logo-text{
        display: none;
    }
    .responsive-logo{
        display: block;
    }


}




/* ============  ============ */